import React, {useEffect} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";

import {loadAirports} from "../../../redux/airports/actions";
import {useAirportsSelector} from "../../../redux/airports/selectors";

const AirportSelect = ({name, label, value, placeholder, setFieldValue}) => {
    const {airportsLoading, airportIds, airportMap} = useAirportsSelector();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        if (!airportIds && !airportsLoading) {
            dispatch(loadAirports(enqueueSnackbar));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        airportsLoading || !airportIds ?
            <CircularProgress size={30} /> :
            <Autocomplete
                freeSolo
                options={airportIds}
                getOptionLabel={(option) => airportMap[option] ? `${airportMap[option].name} (${option})` : ""}
                name={name}
                onChange={(event, newValue) => {
                    console.log("onChange", newValue);
                    setFieldValue(name, newValue || '', true);
                }}
                inputValue={value}
                placeholder={placeholder}
                onInputChange={event => {
                    if (event && event.target.value !== 0) {
                        console.log("onInputChange", event.target.value);
                        setFieldValue(name, event.target.value || "", true);
                    }
                }}
                renderInput={(params) => <TextField {...params} label={label} margin="normal" />}
                selectOnFocus
                fullWidth
            />
    )
};

export default AirportSelect;