import {useShallowEqualSelector} from "../index";

export const useAirportsSelector = () => {
    return useShallowEqualSelector(({ airports: { airportsLoading, airportMap, airportIds } }) => {
        return {
            airportsLoading,
            airportIds,
            airportMap
        }
    });
};