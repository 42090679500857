import React, {useRef} from 'react';

import CfjDialog from "../../../_common/dialog";
import AddLegForm from "../addLegForm";

const EditLegDialog = ({open, onClose, trip, leg, legIndex, setSaving}) => {
    const submitRef = useRef(null);

    return (
        <CfjDialog
            onClose={onClose}
            open={open}
            title="Edit Leg"
            maxWidth="md"
            actions={[
                {label: "cancel", onClick: onClose},
                {label: "Save", onClick: () => {
                    onClose();
                    submitRef.current.click()
                }}
            ]}>
            <AddLegForm trip={trip} leg={leg} legIndex={legIndex} setSaving={setSaving} submitRef={submitRef}  />
        </CfjDialog>
    )
}

export default EditLegDialog;