import React from 'react';
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import WarningIcon from '@material-ui/icons/Warning';

import logo from "../../../../img/cfj-logo.png";

import './index.css';

const ErrorView = () => {
    return (
        <div>
            <AppBar position="sticky" color="primary">
                <Toolbar disableGutters={true}>
                    <div className="home-toolbar-title-container text-align-left flex w100">
                        <div>
                            <img src={logo} className="nav-header-logo" alt="nica logo" />
                        </div>
                        <div className="home-toolbar-title flex align-items-center flex1 marginLeft25">
                            Sorry, something went wrong.
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <div className="text-align-center error-view-container">
                <div className="flex center marginTop30">
                    <WarningIcon htmlColor="#FF0000" fontSize="large" />
                </div>
                <div className="error-view-title marginTop10">
                    Please try again. If the problem persists you can reach out to us at <a href="mailto:contact@carbonfreejetsetter.com">contact@carbonfreejetsetter.com</a>
                </div>
            </div>
        </div>
    )
};

export default ErrorView;