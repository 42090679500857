import React, {useState} from 'react';
import {Card, CardContent, CardActions} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import GasIcon from "@material-ui/icons/LocalGasStation";
import WarningIcon from "@material-ui/icons/Error";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router";
import {fuelGallonsToLbs} from "@devsontap/cfj-core";
import {format} from "date-fns";

import FuelBurnDialog from "./fuelBurn";
import CfjDialog from "../../../_common/dialog";
import EditLegDialog from "../editLegDialog";
import {reconcileFuelBurn, deleteLeg} from "../../../../redux/trips/actions";
import {useTripSelector} from "../../../../redux/trips/selectors";
import {formatTotalCarbon} from "../../../../utils/carbon";

import './index.css';

const TripLegs = ({tripId, value, showLbs}) => {
    const trip = useTripSelector(tripId);

    return (
        <div>
            <Grid container spacing={2}>
                {value.map((leg, index) => (
                    <Grid key={`${tripId}-leg-${index}-${leg.origin}`} item xs={12} md={6}>
                        <TripLeg trip={trip} leg={leg} index={index} showLbs={showLbs} />
                    </Grid>
                ))}
            </Grid>
        </div>
    )
};

const TripLeg = ({trip, leg, index, showLbs}) => {
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showEditLeg, setShowEditLeg] = useState(false);
    const [saving, setSaving] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    const history = useHistory();

    const goBack = () => history.push('/trips');

    const onDeleteLeg = () => {
        setSaving(true);
        setShowDeleteDialog(false);
        dispatch(deleteLeg(trip, index, setSaving, goBack, enqueueSnackbar));
    };

    const getFuelBurn = (value) => {
        if (showLbs) {
            return formatTotalCarbon(fuelGallonsToLbs(value), 1) + " lbs";
        }
        return formatTotalCarbon(value, 1) + " gal";
    };

    return (
        <div>
            <div className="marginBottom10 trip-details-leg-date">
                {format(leg.departureDate, "M/d/yyyy")}
            </div>
            <Card className="trip-details-leg-card">
                <CardContent>
                    <div className="flex trip-details-leg-content">
                        <div className="trip-details-leg-item">
                            <div className="trip-details-leg-label">
                                Distance
                            </div>
                            <Tooltip title={leg.totalMiles}>
                                <div className="trip-details-leg-value">
                                    {formatTotalCarbon(leg.totalMiles, 1)} mi
                                </div>
                            </Tooltip>
                        </div>
                        <div className="trip-details-leg-item">
                            <div className="trip-details-leg-label">
                                &#8494; Fuel
                            </div>
                            <Tooltip title={showLbs ? fuelGallonsToLbs(leg.fuelBurnGallonsEstimated) : leg.fuelBurnGallonsEstimated}>
                                <div className="color-secondary bold trip-details-leg-value">
                                    {getFuelBurn(leg.fuelBurnGallonsEstimated)}
                                </div>
                            </Tooltip>
                        </div>
                        <div className="trip-details-leg-item">
                            <div className="trip-details-leg-label">
                                &#8494; Carbon
                            </div>
                            <Tooltip title={leg.carbonMTEstimated}>
                                <div className="color-secondary bold trip-details-leg-value">
                                    {formatTotalCarbon(leg.carbonMTEstimated, 1)} MT
                                </div>
                            </Tooltip>
                        </div>
                        <div className="trip-details-leg-item">
                            <div className="trip-details-leg-label">
                                Fuel
                            </div>
                            <Tooltip title={showLbs && leg.fuelBurnGallonsActual ? fuelGallonsToLbs(leg.fuelBurnGallonsActual) : leg.fuelBurnGallonsActual}>
                                <div className="color-primary bold trip-details-leg-value">
                                    {leg.fuelBurnGallonsActual ?
                                        getFuelBurn(leg.fuelBurnGallonsActual):
                                        <WarningIcon color="secondary" fontSize="small"/>
                                    }
                                </div>
                            </Tooltip>
                        </div>
                        <div className="trip-details-leg-item">
                            <div className="trip-details-leg-label">
                                Carbon
                            </div>
                            <Tooltip title={leg.carbonMTActual}>
                                <div className="color-primary bold trip-details-leg-value">
                                    {leg.carbonMTActual ?
                                        formatTotalCarbon(leg.carbonMTActual, 1) + " MT" :
                                        <WarningIcon color="secondary" fontSize="small"/>
                                    }
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="marginTop20">
                        {leg.origin} --> {leg.destination} ({leg.aircraftId})
                    </div>
                </CardContent>
                <CardActions>
                    <div className="flex justify-end w100">
                        {saving ?
                            <div className="marginRight20">
                                <CircularProgress size={30} />
                            </div> :
                            <>
                                <div className="trip-details-leg-delete-btn">
                                    <Button variant="text" onClick={() => setShowDeleteDialog(true)}>
                                        <DeleteIcon className="marginRight5"/><Hidden xsDown>Delete</Hidden>
                                    </Button>
                                </div>
                                <div className="trip-details-leg-delete-btn">
                                    <Button variant="text" onClick={() => setShowEditLeg(true)}>
                                        <EditIcon className="marginRight5"/><Hidden xsDown>Edit Leg</Hidden>
                                    </Button>
                                </div>
                                <div>
                                    {leg.carbonMTActual ?
                                        <Button variant="text" onClick={() => setShowEditDialog(true)}>
                                            <GasIcon className="marginRight5"/><Hidden xsDown>Edit Fuel Burn</Hidden>
                                        </Button> :
                                        <Button variant="text" onClick={() => setShowEditDialog(true)}>
                                            <WarningIcon color="secondary" className="marginRight5"/>Add Fuel Burn
                                        </Button>
                                    }
                                </div>
                            </>
                        }
                    </div>
                </CardActions>
            </Card>
            <FuelBurnDialog
                open={showEditDialog}
                onClose={() => setShowEditDialog(false)}
                fuelBurn={showLbs && leg.fuelBurnGallonsActual ? fuelGallonsToLbs(leg.fuelBurnGallonsActual) : leg.fuelBurnGallonsActual}
                showLbs={showLbs}
                onReconcile={fuelBurnGallons => {
                    setSaving(true);
                    dispatch(reconcileFuelBurn(trip, index, fuelBurnGallons, setSaving, enqueueSnackbar));
                    setShowEditDialog(false);
                }}/>
            <CfjDialog
                title="Confirm Delete"
                open={showDeleteDialog}
                onClose={() => setShowDeleteDialog(false)}
                actions={[
                    {label: "cancel", onClick: () => setShowDeleteDialog(false)},
                    {label: "delete", onClick: () => onDeleteLeg()}
                ]}>
                {trip && trip.legs && trip.legs.length > 1 ?
                    "Are you sure you want to delete this Leg?"
                    :
                    "Deleting this leg will delete the whole Trip. Are you sure?"
                }
            </CfjDialog>
            <EditLegDialog
                open={showEditLeg}
                onClose={() => setShowEditLeg(false)}
                trip={trip}
                leg={leg}
                legIndex={index}
                setSaving={setSaving} />
        </div>
    )
}

export default TripLegs;