import {
    TRIPS_LOADING,
    TRIPS_SUCCESS,
    TRIPS_ERROR,
    TRIP_LOADING,
    TRIP_SUCCESS,
    TRIP_DELETED,
    TRIP_ERROR
} from "./types";

export const loadTrips = (enqueueSnackbar) => (
    (dispatch, getState, {api}) => {
        dispatch({ type: TRIPS_LOADING });
        return api.trips.getUserTrips(getState().login.claims.user_id)
            .then(trips => {
                console.log("trips", trips);
                return dispatch({ type: TRIPS_SUCCESS, payload: trips });
            })
            .catch(err => {
                console.error(err);
                dispatch({ type: TRIPS_ERROR });
                if (enqueueSnackbar) {
                    enqueueSnackbar(err.message, {variant: 'error'});
                }
            });
    }
);

export const loadTrip = (tripId, enqueueSnackbar) => (
    (dispatch, getState, {api}) => {
        dispatch({ type: TRIP_LOADING });
        return api.trips.get(tripId)
            .then(trip => {
                console.log("trip", trip);
                return dispatch({ type: TRIP_SUCCESS, payload: trip });
            })
            .catch(err => {
                console.error(err);
                dispatch({ type: TRIP_ERROR });
                if (enqueueSnackbar) {
                    enqueueSnackbar(err.message, {variant: 'error'});
                }
            });
    }
);

export const saveLegByFlight = (trip, legIndex, departureDate, flightNumber, setSaving, goToFlight, resetForm, enqueueSnackbar) => (
    (dispatch, getState, {api}) => {
        return api.trips.estimatedCarbon(departureDate.toISOString(), flightNumber)
            .then(async result => {
                console.log("result", result);

                const leg = {departureDate, flightNumber, ...result};
                const legs = [...trip.legs];
                if (legIndex < 0) {
                    legs.push(leg)
                } else {
                    legs[legIndex] = leg
                }

                dispatch(saveTrip({
                    ...trip,
                    legs
                }, setSaving, goToFlight, resetForm, enqueueSnackbar));
            })
            .catch(err => {
                setSaving(false);
                console.error(err);
                // dispatch({ type: TRIP_ERROR });
                if (enqueueSnackbar) {
                    enqueueSnackbar(err.message, {variant: 'error'});
                }
            });
    }
);

export const saveLegByAirports = (trip, legIndex, departureDate, origin, destination, aircraftId, setSaving, goToFlight, resetForm, enqueueSnackbar) => (
    (dispatch, getState, {api}) => {
        return api.trips.estimatedCarbon(null, null, origin, destination, aircraftId)
            .then(async result => {
                console.log("result", result);

                const leg = {...result, departureDate};
                const legs = [...trip.legs];
                if (legIndex < 0) {
                    legs.push(leg)
                } else {
                    legs[legIndex] = leg
                }

                dispatch(saveTrip({
                    ...trip,
                    legs
                }, setSaving, goToFlight, resetForm, enqueueSnackbar));
            })
            .catch(err => {
                setSaving(false);
                console.error(err);
                // dispatch({ type: TRIP_ERROR });
                if (enqueueSnackbar) {
                    enqueueSnackbar(err.message, {variant: 'error'});
                }
            });
    }
);

export const reconcileFuelBurn = (trip, legIndex, fuelBurnGallons, setSaving, enqueueSnackbar) => (
    (dispatch, getState, {api}) => {
        return api.trips.actualCarbon(fuelBurnGallons)
            .then(async result => {
                console.log("actualCarbon result", result);

                // Construct the update
                const legs = [...trip.legs];
                legs[legIndex] = {
                    ...trip.legs[legIndex],
                    carbonMTActual: result,
                    fuelBurnGallonsActual: fuelBurnGallons
                };

                return dispatch(saveTrip({
                    ...trip,
                    legs
                }, setSaving, null, null, enqueueSnackbar));
            })
            .catch(err => {
                setSaving(false);
                console.error(err);
                dispatch({ type: TRIP_ERROR });
                if (enqueueSnackbar) {
                    enqueueSnackbar(err.message, {variant: 'error'});
                }
            });
    }
);

export const saveTrip = (trip, setSaving, goToFlight, resetForm, enqueueSnackbar) => (
    (dispatch, getState, {api}) => {
        return api.trips.save(trip)
            .then(tripUpdate => {
                console.log("saveTrip", tripUpdate);
                setSaving(false);
                dispatch({ type: TRIP_SUCCESS, payload: tripUpdate});
                if (!trip.id && goToFlight) {
                    goToFlight(tripUpdate.id);
                }
                if (resetForm) {
                    resetForm();
                }
            })
            .catch(err => {
                setSaving(false);
                console.error(err);
                dispatch({ type: TRIP_ERROR });
                if (enqueueSnackbar) {
                    enqueueSnackbar(err.message, {variant: 'error'});
                }
            });
    }
);

export const deleteLeg = (trip, legIndex, setSaving, goBack, enqueueSnackbar) => (
    (dispatch, getState, {api}) => {
        if (trip.legs.length > 1) {
            const legs = [...trip.legs];
            legs.splice(legIndex, 1);
            return dispatch(saveTrip({
                ...trip,
                legs
            }, setSaving, null, null, enqueueSnackbar))
        } else {
            return dispatch(deleteTrip(trip.id, setSaving, goBack, enqueueSnackbar));
        }
    }
);

export const deleteTrip = (tripId, setSaving, goBack, enqueueSnackbar) => (
    (dispatch, getState, {api}) => {
        return api.trips.delete(tripId)
            .then(() => {
                setSaving(false);
                dispatch({ type: TRIP_DELETED, payload: tripId });
                if (goBack) {
                    goBack();
                }
            })
            .catch(err => {
                setSaving(false);
                console.error("deleteTrip", err);
            })
    }
);