import React, {useEffect, useRef, useState} from 'react';
import {Formik, Form} from "formik";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import InputAdornment from '@material-ui/core/InputAdornment';
import BackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import {trip as TripModel} from "@devsontap/cfj-api/core/models"
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router";

import TripLegs from "./legs";
import AddLeg from "./addLeg";
import TripDetailsTotals from "./totals";
import Loading from "../../_common/loading";
import CustomFormikField from "../../_common/formik/customField";
import CfjDialog from "../../_common/dialog";
import {useTripsSelector, useTripSelector} from "../../../redux/trips/selectors";
import {deleteTrip, loadTrip, saveTrip} from "../../../redux/trips/actions";

import './index.css';

const TripDetails = ({tripId, onClose}) => {
    const [saving, setSaving] = useState(false);
    const [showLbs, setShowLbs] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const trip = useTripSelector(tripId);
    const {tripLoading} = useTripsSelector();
    const dispatch = useDispatch();
    const resetRef = useRef();
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();

    useEffect(() => {
        if (!trip) {
            dispatch(loadTrip(tripId));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (trip && resetRef.current) {
            console.log("resetting form");
            resetRef.current.click();
        }
    }, [trip]);

    const onDeleteTrip = () => {
        setShowDeleteDialog(false);
        setSaving(true);
        dispatch(deleteTrip(tripId, setSaving, () => history.push('/trips'), enqueueSnackbar));
    };

    return (
        <div className="h100 flex column">
            <AppBar position="sticky" color="primary">
                <Toolbar disableGutters={true}>
                    <div className="home-toolbar-title-container text-align-left w100 flex">
                        <div>
                            <IconButton onClick={onClose} >
                                <BackIcon htmlColor="#ffffff" />
                            </IconButton>
                        </div>
                        <div className="home-toolbar-title flex align-items-center flex1">
                            {tripId !== "add" ? "Edit Trip" : "Add Trip"}
                        </div>
                        {trip && trip.id &&
                            <>
                                <div className="flex align-items-center marginRight10">
                                    gal <Switch checked={showLbs} onChange={event => setShowLbs(event.target.checked)} /> lbs
                                </div>
                                <div className="marginRight20">
                                    {saving ?
                                        <CircularProgress size={30} color="secondary"/> :
                                        <IconButton onClick={() => setShowDeleteDialog(true)}>
                                            <DeleteIcon htmlColor="#ffffff"/>
                                        </IconButton>
                                    }

                                </div>
                            </>
                        }
                    </div>
                </Toolbar>
            </AppBar>
            <div className="trip-details-container bg-app flex1 scrollY">
                {tripLoading || !trip ?
                    <Loading size={45} />
                    :
                    <>
                        <Formik
                            initialValues={{
                                name: '',
                                ...trip
                            }}
                            onSubmit={(values) => {
                                console.log("saving", values);
                                setSaving(true);
                                dispatch(saveTrip(values, setSaving, null, null, enqueueSnackbar));
                            }}
                            validationSchema={TripModel.SCHEMA}>
                            {({resetForm}) => (
                                <Form>
                                    <input type="button" style={{display: "none"}} ref={resetRef} onClick={resetForm} />
                                    {trip && trip.id &&
                                        <div className="trip-details-name marginBottom30">
                                            <CustomFormikField
                                                CustomComponent={TextField}
                                                name="name"
                                                label="Trip Name (Optional)"
                                                placeholder="Best Trip Ever"
                                                InputProps={{
                                                    endAdornment: saving ?
                                                        <CircularProgress size={25} /> :
                                                        <InputAdornment position="end">
                                                            <IconButton type="submit">
                                                                <SaveIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                }}
                                                fullWidth />
                                        </div>
                                    }
                                    <CustomFormikField
                                        CustomComponent={TripLegs}
                                        tripId={tripId}
                                        showLbs={showLbs}
                                        name="legs"/>
                                </Form>
                            )}
                        </Formik>
                        <AddLeg trip={trip} />
                    </>
                }
            </div>
            <TripDetailsTotals trip={trip} />
            <CfjDialog
                title="Confirm Delete"
                open={showDeleteDialog}
                onClose={() => setShowDeleteDialog(false)}
                actions={[
                    {label: "cancel", onClick: () => setShowDeleteDialog(false)},
                    {label: "delete", onClick: () => onDeleteTrip()}
                ]}>
                Are you sure you want to delete this Trip?
            </CfjDialog>
        </div>
    )
};

export default TripDetails;