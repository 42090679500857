import {
    USER_AUTHED,
    EMAIL_LINK_INIT,
    USER_UNAUTHED,
    LOGGING_IN
} from "./types";

export const userUnauthed = () => {
    return {type: USER_UNAUTHED};
};

export const userAuthed = (claims) => (
    (dispatch) => {
        dispatch({type: USER_AUTHED, payload: claims});
    }
);

export const initEmailLinkAuth = (email, setLoading, setEmail, enqueueSnackbar) => (
    (dispatch, getState, {api}) => {
        dispatch({ type: EMAIL_LINK_INIT, payload: email });
        return api.sendEmailLink(email)
            .then(() => {
                setLoading(false);
                setEmail(email);
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
            });
    }
);

export const signInWithEmailLink = (email, setLoading, enqueueSnackbar) => (
    (dispatch, getState, {api}) => {
        if (api.isSignInWithEmailLink() && email) {
            dispatch({ type: LOGGING_IN });
            return api.loginWithLink(email)
                .catch(error => {
                    // TODO: Need to remember what this was for
                    // dispatch({ type: CLEAR_LOGIN });
                    setLoading(false);
                    console.error(error);
                    enqueueSnackbar(error.message, {variant: 'error'});
                });
        }
        enqueueSnackbar("Trying to sign in without SignIn Link", {variant: 'error'});
        // TODO: Need to remember what this was for
        // return dispatch({ type: CLEAR_LOGIN });
        return false;
    }
);

export const logout = () => (
    async (dispatch, getState, {api}) => {
        await api.logout()
            .then(() => localStorage.clear());
    }
);

export const impersonateUser = (email, setLoading, onClose, enqueueSnackbar) => (
    async (dispatch, getState, api) => {
        const response = await api.getUserToken(email);
        console.log("token response", response);
        if (response.data) {
            onClose();
            return api.loginWithToken(response.data);
        } else {
            setLoading(false);
            enqueueSnackbar("no user found", {variant: 'error'});
        }

    }
);