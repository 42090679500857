import {
    TRIPS_LOADING,
    TRIPS_SUCCESS,
    TRIPS_ERROR,
    TRIP_LOADING,
    TRIP_SUCCESS,
    TRIP_DELETED,
    TRIP_ERROR
} from "./types";

const INITIAL_STATE = {
    tripsLoading: false,
    tripLoading: false,
    tripIds: null,
    tripMap: {},
};

export default (state = INITIAL_STATE, action) => {
    let tripIds = null;
    let tripMap = null;
    switch (action.type) {
        case TRIPS_LOADING:
            return {
                ...state,
                tripsLoading: true
            };
        case TRIPS_SUCCESS:
            return {
                ...state,
                tripsLoading: false,
                tripMap: action.payload.reduce((ret, trip) => {
                    ret[trip.id] = trip;
                    return ret;
                }, {}),
                tripIds: action.payload.map(trip => trip.id)
            };
        case TRIPS_ERROR:
            return {
                ...state,
                tripsLoading: false
            };
        case TRIP_LOADING:
            return {
                ...state,
                tripLoading: true
            };
        case TRIP_SUCCESS:
            tripIds = [...state.tripIds];
            if (!tripIds.includes(action.payload.id)) {
                tripIds = [action.payload.id, ...tripIds];
            }
            return {
                ...state,
                tripLoading: false,
                tripMap: {
                    ...state.tripMap,
                    [action.payload.id]: action.payload
                },
                tripIds
            };
        case TRIP_ERROR:
            return {
                ...state,
                tripLoading: false
            };
        case TRIP_DELETED:
            tripMap = {...state.tripMap};
            delete tripMap[action.payload];

            tripIds = [...state.tripIds];
            tripIds.splice(tripIds.indexOf(action.payload), 1);

            return {
                ...state,
                tripMap,
                tripIds
            };
        default:
            return state;
    }
}

