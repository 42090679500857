import React, {useState, useRef} from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";

import AddLegForm from "../addLegForm";

import './index.css';

const AddLeg = ({trip}) => {
    const [saving, setSaving] = useState(false);
    const submitRef = useRef(null);

    return (
        <div className="marginTop20">
            <Card>
                <CardContent>
                    <AddLegForm trip={trip} submitRef={submitRef} setSaving={setSaving} />
                </CardContent>
                <CardActions>
                    <div className="flex justify-end w100">
                        {saving ?
                            <CircularProgress size={30} />
                            :
                            <Button variant="text" onClick={() => {
                                console.log("submitRef", submitRef.current);
                                submitRef.current.click()
                            }}>
                                <SaveIcon className="marginRight5" /> Save
                            </Button>
                        }
                    </div>
                </CardActions>
            </Card>
        </div>
    )
};

export default AddLeg;