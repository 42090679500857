import React from 'react';
import {FastField, Field, ErrorMessage} from "formik";

import './index.css';

const CustomFormikField = ({name, CustomComponent, fast, errorField, ...otherProps}) => {
    const FormikField = fast ? FastField : Field;
    return (
        <FormikField name={name}>
            {({ field: {value, onChange}, meta: {error, touched} }) => {
                return (
                    <>
                        <div>
                            <CustomComponent {...{name, value, onChange, ...otherProps}} error={otherProps.type === "checkbox" ? error : Boolean(touched && error)} />
                        </div>
                        <div>
                            <ErrorMessage name={name} render={error => <div className="error">{errorField ? error[errorField] : error.toString()}</div>} />
                        </div>
                    </>
                )
            }}
        </FormikField>
)};

export default CustomFormikField;