import {
    AIRPORTS_LOADING,
    AIRPORTS_SUCCESS,
    AIRPORTS_ERROR
} from "./types";

const INITIAL_STATE = {
    airportsLoading: false,
    airportMap: {},
    airportIds: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AIRPORTS_LOADING:
            return {
                ...state,
                airportsLoading: true
            };
        case AIRPORTS_SUCCESS:
            return {
                ...state,
                airportsLoading: false,
                airportMap: action.payload,
                airportIds: Object.keys(action.payload).sort((aid1, aid2) => {
                    return action.payload[aid1].name.localeCompare(action.payload[aid2].name);
                })
            };
        case AIRPORTS_ERROR:
            return {
                ...state,
                airportsLoading: false
            };
        default:
            return state;
    }
}

