import {
    AIRPORTS_LOADING,
    AIRPORTS_SUCCESS,
    AIRPORTS_ERROR
} from "./types"

export const loadAirports = (enqueueSnackbar) => (
    (dispatch, getState, {api}) => {
        dispatch({ type: AIRPORTS_LOADING });
        return api.airports.getAll()
            .then(airports => {
                dispatch({ type: AIRPORTS_SUCCESS, payload: airports });
            })
            .catch(err => {
                console.error("loadAirports", err);
                dispatch({ type: AIRPORTS_ERROR });
                enqueueSnackbar(err.message, {variant: "error"});
            })
    }
);