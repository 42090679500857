export const formatTotalCarbon = (totalCarbon, fractionDigits = 2) => {
    let suffix = "";
    let factor = 1;

    if (totalCarbon >= 1000000) {
        suffix = "M";
        factor = 1000000;
    } else if (totalCarbon >= 1000) {
        suffix = "K";
        factor = 1000;
    }

    return `${(totalCarbon / factor).toFixed(fractionDigits)}${suffix}`;
};