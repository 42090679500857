import {
    AIRCRAFT_LOADING,
    AIRCRAFT_SUCCESS,
    AIRCRAFT_ERROR
} from "./types";

const INITIAL_STATE = {
    aircraftLoading: false,
    aircraftMap: {},
    aircraftIds: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AIRCRAFT_LOADING:
            return {
                ...state,
                aircraftLoading: true
            };
        case AIRCRAFT_SUCCESS:
            return {
                ...state,
                aircraftLoading: false,
                aircraftMap: action.payload,
                aircraftIds: Object.keys(action.payload).sort((aid1, aid2) => {
                    return action.payload[aid1].model.localeCompare(action.payload[aid2].model);
                })
            };
        case AIRCRAFT_ERROR:
            return {
                ...state,
                aircraftLoading: false
            };
        default:
            return state;
    }
}

