import React, {useEffect, useMemo} from 'react';
import {Link} from 'react-router-dom'
import Fab from "@material-ui/core/Fab";
import AddIcon from '@material-ui/icons/Add';
import {useParams, useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import {compareDesc} from "date-fns";

import TripItem from "./item";
import TripDetails from "./details";
import TripsTotals from "./totals";
import FullscreenDialog from "../_common/dialog/fullScreen";
import {loadTrips} from "../../redux/trips/actions";
import {useTripsSelector} from "../../redux/trips/selectors";

import './index.css';
import Loading from "../_common/loading";

const Trips = () => {
    const {tripId} = useParams();
    const history = useHistory();
    const {tripsLoading, tripIds, tripMap} = useTripsSelector();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        dispatch(loadTrips(enqueueSnackbar));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useMemo(() => {
        if (tripIds) {
            tripIds.sort((id1, id2) => {
                return compareDesc(tripMap[id1].legs[0].departureDate, tripMap[id2].legs[0].departureDate)
            });
        }
    }, [tripIds]); // eslint-disable-line react-hooks/exhaustive-deps

    const onClose = () => history.push('/trips');

    return (
        <div className="flex column h100 bg-app">
            <div className="trips-fab-container">
                <Link to={`/trips/add`}>
                    <Fab color="secondary" aria-label="Add" onClick={() => {}}>
                        <AddIcon htmlColor="#ffffff" />
                    </Fab>
                </Link>
            </div>
            <div className="trips-container flex1 scrollY">
                {tripsLoading ?
                    <Loading size={45} />
                    :
                    <div>
                        {tripIds && tripIds.length ?
                            tripIds.map(tripId => (
                                <TripItem key={tripId} tripId={tripId} />
                            ))
                            :
                            <div>
                                No Trips to display.
                            </div>
                        }
                    </div>

                }
            </div>
            <div>
                <TripsTotals tripIds={tripIds} tripMap={tripMap} />
            </div>
            <FullscreenDialog onClose={onClose} open={Boolean(tripId)}>
                <TripDetails tripId={tripId} onClose={onClose} />
            </FullscreenDialog>
        </div>
    )
};

export default Trips;