import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';
import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';

import api from './api';
import reducers from './redux';
import Home from './components/home';
import Login from "./components/login";
import ErrorBoundary from "./components/_common/errorBoundary";
import AuthProvider from "./components/_common/authProvider";

import './App.css';
import '@devsontap/dot-react-common/core/css/atomic.css';

/**
 * This is the Material-UI theme config for the Application
 */
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#194789',
            // dark: '#c20000',
            contrastText: "#fafafa"
        },
        secondary: {
            main: '#ffb74d',
            contrastText: '#ffffff',
        },
        // error: will use the default color
    },
    overrides: {
        MuiCardMedia: {
            root: {
                backgroundSize: "contain"
            }
        }
    }
});

/**
 * Create a Redux store with Thunk middleware
 */
const createStoreWithMiddleware = applyMiddleware(reduxThunk.withExtraArgument({ api }))(createStore);
const store = createStoreWithMiddleware(reducers,
    process.env.NODE_ENV === "development" &&
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const App = () => {
    return (
        <MuiThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={3000}>
                <Provider store={store}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="App">
                            <ErrorBoundary>
                                <AuthProvider unAuthed={<Login />}>
                                    <Home />
                                </AuthProvider>
                            </ErrorBoundary>
                        </div>
                    </MuiPickersUtilsProvider>
                </Provider>
            </SnackbarProvider>
        </MuiThemeProvider>
    );
};

export default App;
