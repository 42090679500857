import React from 'react';
import {NavLink, Route, Switch} from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {useDispatch} from "react-redux";
import TripsIcon from '@material-ui/icons/Flight';

import {logout} from "../../redux/login/actions";

import logo from '../../img/cfj-logo-ko.svg';

import './index.css';

const items = [
    { label: 'Trips', path: '/trips', Icon: TripsIcon },
];

const Nav = () => {
    const dispatch = useDispatch();

    return (
        <Paper className="nav-container" square>
            <div className="h100 flex column">
                <div className="nav-header">
                    <div className="flex justify-end" style={{color: "white"}}>
                        <Button variant="text" color="inherit" onClick={() => dispatch(logout())}>
                            Log Out
                        </Button>
                    </div>
                    <div>
                        <img src={logo} className="nav-header-logo" alt="cfj logo" />
                    </div>
                </div>
                <div className="admin-nav-card-items flex1 flex column">
                    <List component="nav">
                        {items.map(({ path, label, Icon }, index) => {
                            return (
                                <NavLink to={path} className="nav-item-text" activeClassName="nav-item-text-selected" key={`nav-item-${label}`}>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <Switch>
                                                <Route path={path} render={() => <Icon htmlColor="#1c5c7f" />} />
                                                <Route render={() => <Icon htmlColor="#A0A0A0" />} />
                                            </Switch>
                                        </ListItemIcon>
                                        <div className="nav-item-label flex align-items-center">
                                            {label}
                                        </div>
                                    </ListItem>
                                </NavLink>
                            );
                        })}
                    </List>
                    <div className="flex1 flex col align-end justify-content-center" style={{ paddingBottom: 10, color: "gray"}}>
                        <Typography color="default">OFFSET MANAGEMENT SYSTEM</Typography>
                    </div>
                </div>
            </div>
        </Paper>
    )

};

export default Nav;