import React, {useRef} from 'react';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import TextField from "@material-ui/core/TextField";
import {fuelLbsToGallons} from "@devsontap/cfj-core";

import CfjDialog from "../../../../_common/dialog";
import CustomFormikField from "../../../../_common/formik/customField";

import './index.css';

const FuelBurnDialog = ({open, onClose, onReconcile, fuelBurn, showLbs}) => {
    const saveRef = useRef(null);

    return (
        <CfjDialog
            onClose={onClose}
            open={open}
            title="Fuel Burned"
            actions={[
                {label: "Cancel", onClick: onClose},
                {label: "Save", onClick: () => saveRef.current.click()}
            ]}>
            <Formik
                initialValues={{ fuelBurn: fuelBurn || '' }}
                onSubmit={values => {
                    onReconcile(showLbs ? fuelLbsToGallons(values.fuelBurn) : values.fuelBurn);
                }}
                validationSchema={Yup.object().shape({
                    fuelBurn: Yup.number().required()
                })}>
                <Form>
                    <input type="submit" style={{display: "none"}} ref={saveRef} />
                    <div className="fuel-burn-value">
                        <CustomFormikField
                            CustomComponent={TextField}
                            name="fuelBurn"
                            label={`Fuel Consumed (in ${showLbs ? "lbs" : "gal"})`}
                            placeholder="2748"
                            type="tel"
                            inputProps={{maxLength: 10, autoComplete: "off"}}
                            fullWidth />
                    </div>
                </Form>
            </Formik>
        </CfjDialog>
    )
};

export default FuelBurnDialog;