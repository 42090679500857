import React from 'react';
import {Card, CardContent} from "@material-ui/core";
import {useHistory} from "react-router";
import {format} from "date-fns";
import CheckIcon from "@material-ui/icons/Check";
import WarningIcon from "@material-ui/icons/Error";
import ArrowRight from "@material-ui/icons/KeyboardArrowRight";

import {useTripSelector} from "../../../redux/trips/selectors";
import {formatTotalCarbon} from "../../../utils/carbon";

import './index.css';

const TripItem = ({tripId}) => {
    const trip = useTripSelector(tripId);
    const history = useHistory();

    const goToTrip = () => history.push(`/trips/${tripId}`);

    let reconciled = true;
    let totalCarbon = 0;
    if (trip && trip.legs) {
        trip.legs.forEach(leg => {
            reconciled = reconciled && leg.carbonMTActual;
            totalCarbon += leg.carbonMTActual ? leg.carbonMTActual : leg.carbonMTEstimated;
        })
    }

    return (
        <Card className="trip-item-card marginBottom20">
            <CardContent onClick={goToTrip}>
                <div className="flex">
                    <div className="text-align-left flex1">
                        {trip.name &&
                            <div className="trip-item-name">
                                {trip.name}
                            </div>
                        }
                        {trip.legs.map((leg, index) => (
                            <div key={`${tripId}-${index}-${leg.origin}`} className="marginBottom15">
                                <div className="trip-item-leg-label">
                                    {leg.departureDate ? format(leg.departureDate, "M/d/yyyy") : "----"}
                                </div>
                                <div>
                                    {leg.origin} --> {leg.destination}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="flex center">
                        <div className={`flex column center trip-item-total-circle ${reconciled ? "bg-primary" : "bg-secondary"}`}>
                            <div style={{marginTop: "-10px"}}>
                                {reconciled ?
                                    <CheckIcon htmlColor="#ffffff" /> :
                                    <WarningIcon htmlColor="#ffffff" />
                                }
                            </div>
                            <div className="trip-item-total-carbon">
                                {formatTotalCarbon(totalCarbon)}
                            </div>
                        </div>
                    </div>
                    <div className="flex center trip-item-chevron">
                        <ArrowRight fontSize="inherit" />
                    </div>
                </div>
            </CardContent>
        </Card>
    )
};

export default TripItem;