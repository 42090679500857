import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import "firebase/functions";
import "firebase/auth";
import CfjApi from '@devsontap/cfj-api';

function initFirebase() {
    let config = isDev() ?
        {
            apiKey: "AIzaSyDX7WfnRnGlii2bQkHrvbPOlmRCjMgVKOU",
            authDomain: "cfj-dev.firebaseapp.com",
            databaseURL: "https://cfj-dev.firebaseio.com",
            projectId: "cfj-dev",
            storageBucket: "cfj-dev.appspot.com",
            messagingSenderId: "553617892255",
            appId: "1:553617892255:web:e67d64e0debe4072db53aa",
            measurementId: "G-5SD339PWZT"
        } :
        {
            apiKey: "AIzaSyDgR-_fbfQBvafjqswQ267tL9ZzOQESENs",
            authDomain: "cfj-prod.firebaseapp.com",
            databaseURL: "https://cfj-prod.firebaseio.com",
            projectId: "cfj-prod",
            storageBucket: "cfj-prod.appspot.com",
            messagingSenderId: "681857812844",
            appId: "1:681857812844:web:c9c938b530fb6c0bb34b49",
            measurementId: "G-RLS6DCCQ0G"
        };
    firebase.initializeApp(config);
    // firebase.functions().useFunctionsEmulator("http://localhost:5001");
}

initFirebase();
const api = new CfjApi(firebase.firestore, firebase.database, firebase.functions, firebase.auth, firebase.storage);

function isDev() {
    return document.domain !== "app.carbonfreejetsetter.com" && document.domain !== "app.offset-solutions.com" && document.domain !== "cfj-prod.web.app";
}

/**
 * Firebase Auth callback when state changes
 * @param onAuthChanged
 */
api.onAuthStateChanged = (onAuthChanged) => {
    firebase.auth().onAuthStateChanged(onAuthChanged);
};

api.sendEmailLink = (email) => {
    return firebase.auth().sendSignInLinkToEmail(email, {
        url: `${document.location.origin}/signInWithLink`,
        handleCodeInApp: true
    })
};

api.isSignInWithEmailLink = () => {
    return firebase.auth().isSignInWithEmailLink(window.location.href);
};

api.loginWithLink = (email) => {
    if (email) {
        return firebase.auth().signInWithEmailLink(email, window.location.href);
    }
    return false;
};

api.logout = () => {
    console.log("IM SIGNING OUT NOW!!");
    return firebase.auth().signOut();
};

export default api;
