import {
    AIRCRAFT_LOADING,
    AIRCRAFT_SUCCESS,
    AIRCRAFT_ERROR
} from "./types"

export const loadAircraft = (enqueueSnackbar) => (
    (dispatch, getState, {api}) => {
        dispatch({ type: AIRCRAFT_LOADING });
        return api.aircraft.getAll()
            .then(aircraft => {
                dispatch({ type: AIRCRAFT_SUCCESS, payload: aircraft });
            })
            .catch(err => {
                console.error("loadAircraft", err);
                dispatch({ type: AIRCRAFT_ERROR });
                enqueueSnackbar(err.message, {variant: "error"});
            })
    }
);