import {useShallowEqualSelector} from "../index";

export const useTripsSelector = () => {
    return useShallowEqualSelector(({ trips: { tripsLoading, tripIds, tripLoading, tripMap } }) => {
        return {
            tripsLoading,
            tripIds,
            tripLoading,
            tripMap
        }
    });
};

export const useTripSelector = (tripId) => {
    return useShallowEqualSelector(({ login: {claims: { user_id }}, trips: { tripMap } }) => {
        if (tripId === "add") {
            return {
                userId: user_id,
                legs: [],
            };
        }
        return tripMap[tripId];
    });
};