import { combineReducers } from 'redux'
import { useSelector, shallowEqual } from 'react-redux';

import aircraft from './aircraft';
import airports from './airports';
import auth from './auth';
import login from './login';
import trips from './trips';

const RootReducer = combineReducers({
    aircraft,
    airports,
    auth,
    login,
    trips
});

/**
 * Helper function for wrapping a selector with a shallowEqual equality function
 */
export const useShallowEqualSelector = (selector) => {
    return useSelector(selector, shallowEqual);
};


export default RootReducer;