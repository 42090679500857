import React from 'react';

import {formatTotalCarbon} from "../../../utils/carbon";

import './index.css';

const TripsTotals = ({tripIds, tripMap}) => {
    let totalCarbon = 0;
    let reconciled = true;

    if (tripIds) {
        tripIds.forEach(tripId => {
            const trip = tripMap[tripId];
            if (trip && trip.legs) {
                trip.legs.forEach(leg => {
                    totalCarbon += leg.carbonMTActual ? leg.carbonMTActual : leg.carbonMTEstimated;
                    reconciled = reconciled && leg.carbonMTActual;
                });
            }
        })
    }

    return (
        <div className={`flex justify-end trips-totals-container ${reconciled ? "bg-primary" : "bg-secondary"}`}>
            <div className="flex center marginRight10 color-white bold trips-totals-label">
                TOTAL
            </div>
            <div className="color-white bold">
                {formatTotalCarbon(totalCarbon)} MT
            </div>
        </div>
    )
};

TripsTotals.defaultProps = {
    tripMap: {}
};

export default TripsTotals;