import React from 'react';
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {Switch, Route, BrowserRouter as Router} from 'react-router-dom';

import SignIn from "./signin";
import LoginVerification from "./verify";
import {useLoginSelector} from "../../redux/login/selectors";

import './index.css';

const Login = () => {
    const {authEmail} = useLoginSelector();
    console.log("login render", authEmail);
    return (
        <div className="login-container flex column">
            <AppBar position="sticky" color="primary">
                <Toolbar disableGutters={true}>
                    <div className="home-toolbar-title-container text-align-left flex w100">
                        Offset Solutions
                    </div>
                </Toolbar>
            </AppBar>
            <div className="login-content flex column align-items-center flex1">
                <div className="login-background" />
                <Card className="login-card">
                    <CardContent>
                        <Router>
                            <Switch>
                                <Route path="/signInWithLink" component={LoginVerification} />
                                <Route>
                                    <SignIn />
                                </Route>
                            </Switch>
                        </Router>
                    </CardContent>
                </Card>
            </div>
        </div>
    )
};

export default Login;