import React, {useState} from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from "@material-ui/core/Hidden";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import Nav from '../nav';
import Trips from '../trips';

import './index.css';

const Home = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    return (
        <Router>
            <Hidden smDown>
                <div className="home-nav-container">
                    <Nav />
                </div>
            </Hidden>
            <Hidden mdUp>
                <SwipeableDrawer
                    open={drawerOpen}
                    onClose={() => { setDrawerOpen(false); }}
                    onOpen={() => { setDrawerOpen(true); }}>
                    <Nav closeDrawer={() => { setDrawerOpen(false); }} />
                </SwipeableDrawer>
            </Hidden>
            <div className="home-content-container relative h100 flex column">
                <AppBar position="sticky" color="primary">
                    <Toolbar disableGutters={true}>
                        <div className="home-toolbar-title-container text-align-left flex w100">
                            <Hidden mdUp>
                                <IconButton onClick={() => { setDrawerOpen(true); }} classes={{root: "home-toolbar-menu-btn"}}>
                                    <MenuIcon htmlColor="#ffffff"/>
                                </IconButton>
                            </Hidden>
                            <div className="home-toolbar-title flex align-items-center flex1">
                                <Switch>
                                    <Route path="/trips" render={() => "Trips"} />
                                    <Route render={() => "Trips"} />
                                </Switch>
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
                <div className="flex1 scrollY">
                    <Switch>
                        <Route path="/trips/:tripId?" component={Trips} />
                        <Route path="/" render={() => <Redirect to="/trips" />} />
                    </Switch>
                </div>
            </div>
        </Router>
    )
};

export default Home;