import {useShallowEqualSelector} from "../index";

export const useAircraftSelector = () => {
    return useShallowEqualSelector(({ aircraft: { aircraftLoading, aircraftMap, aircraftIds } }) => {
        return {
            aircraftLoading,
            aircraftIds,
            aircraftMap
        }
    });
};