import React, {useEffect} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";

import {loadAircraft} from "../../../redux/aircraft/actions";
import {useAircraftSelector} from "../../../redux/aircraft/selectors";

const AircraftSelect = ({name, label, value, setFieldValue}) => {
    const {aircraftLoading, aircraftIds, aircraftMap} = useAircraftSelector();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        if (!aircraftIds && !aircraftLoading) {
            dispatch(loadAircraft(enqueueSnackbar));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    console.log("AircraftSelect", value);

    return (
        aircraftLoading || !aircraftIds ?
            <CircularProgress size={30} /> :
            <Autocomplete
                fullWidth
                options={aircraftIds}
                getOptionLabel={(option) => aircraftMap[option] ? aircraftMap[option].model : ""}
                name={name}
                value={value}
                onChange={(event, newValue) => {
                    setFieldValue(name, newValue, true);
                }}
                renderInput={(params) => <TextField {...params} label={label} margin="normal" />}
            />
    )
};

export default AircraftSelect;