import React, {useState} from 'react';
import Button from "@material-ui/core/Button";
import {useDispatch} from "react-redux";
import {initEmailLinkAuth} from "../../../redux/login/actions";
import {useSnackbar} from "notistack";

import LoginForm from "../form";

import './index.css';

const SignIn = () => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(null);
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    const initiateSignIn = async (email) => {
        dispatch(initEmailLinkAuth(email, setLoading, setEmail, enqueueSnackbar));
    };

    return (
        <div>
            <div className="lato login-title">
                {email ?
                    "Sign-in email sent"
                    :
                    "Sign in with email"
                }
            </div>
            <div>
                {!Boolean(email) ?
                    <LoginForm
                        loading={loading}
                        loginButtonLabel="Sign In"
                        helperText="You will be emailed a link to login"
                        onSubmit={values => {
                            setLoading(true);
                            return initiateSignIn(values.email);
                        }} />
                    :
                    <div>
                        <div className="login-message">
                            A sign-in email with additional instructions was sent to <strong>{email}</strong>.<br/><br/>
                            Check your email to complete sign-in.
                        </div>
                        <div className="flex justify-end marginTop20">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setEmail(null)}>
                                Back
                            </Button>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
};

export default SignIn;