import React from 'react';
import ErrorView from "./view";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            errorInfo: null
        };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error, errorInfo });
    }

    render() {
        if (Boolean(this.state.error)) {
            return (
                <ErrorView error={this.state.error} errorInfo={this.state.errorInfo} />
            )
        }

        return this.props.children;
    }
}

export default ErrorBoundary;