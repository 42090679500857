import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import { DateTimePicker } from "@material-ui/pickers";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from "@material-ui/icons/Help";
import {Formik, Form} from 'formik';
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router";
import * as Yup from 'yup';

import AirportSelect from "../../../_common/airportSelect";
import AircraftSelect from "../../../_common/aircraftSelect";
import CustomFormikField from "../../../_common/formik/customField";
import {saveLegByFlight, saveLegByAirports} from "../../../../redux/trips/actions";

import './index.css';

const MODES = {
    FLIGHT_NUMBER: "FLIGHT_NUMBER",
    AIRPORTS: "AIRPORTS"
};

const FlightNumberQuerySchema = Yup.object().shape({
    departureDate: Yup.date().required(),
    flightNumber: Yup.string().required()
});

const AirportQuerySchema = Yup.object().shape({
    origin: Yup.string().required(),
    destination: Yup.string().required(),
    aircraftId: Yup.string().required()
});

const AddLegForm = ({trip, leg, legIndex, submitRef, setSaving}) => {
    const [mode, setMode] = useState(MODES.AIRPORTS);
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();

    const goToFlight = tripId => history.push(`/trips/${tripId}`);

    return (
        <div className="add-leg-form-container">
            <div>
                <ToggleButtonGroup
                    value={mode}
                    exclusive
                    onChange={(event, mode) => {
                        if (mode) {
                            setMode(mode);
                        }
                    }}
                    aria-label="text alignment">
                    <ToggleButton value={MODES.AIRPORTS} aria-label="centered">
                        Origin / Destination
                    </ToggleButton>
                    <ToggleButton value={MODES.FLIGHT_NUMBER} aria-label="left aligned">
                        Flight #
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            <Formik
                initialValues={{
                    departureDate: new Date(),
                    flightNumber: '',
                    origin: '',
                    destination: '',
                    aircraftId: '',
                    ...leg
                }}
                onSubmit={(values, {resetForm}) => {
                    console.log("onSubmit");
                    setSaving(true);

                    const {departureDate, flightNumber, origin, destination, aircraftId} = values;
                    if (mode === MODES.FLIGHT_NUMBER) {
                        dispatch(saveLegByFlight(trip, legIndex, departureDate, flightNumber, setSaving, goToFlight, resetForm, enqueueSnackbar));
                    } else {
                        dispatch(saveLegByAirports(trip, legIndex, departureDate, origin, destination, aircraftId, setSaving, goToFlight, resetForm, enqueueSnackbar));
                    }
                }}
                validationSchema={mode === MODES.FLIGHT_NUMBER ? FlightNumberQuerySchema : AirportQuerySchema}>
                {({values, setFieldValue}) => (
                    <Form>
                        <input type="Submit" ref={submitRef} style={{display: "none"}} />
                        <div className="flex marginTop20 add-leg-form">
                            {mode === MODES.FLIGHT_NUMBER ?
                                <>
                                    <div>
                                        <CustomFormikField
                                            CustomComponent={DateTimePicker}
                                            key="departureDate"
                                            name="departureDate"
                                            label="Departure Date"
                                            value={values.departureDate}
                                            onChange={date => {
                                                setFieldValue("departureDate", date, false);
                                            }}
                                            fullWidth
                                        />
                                    </div>
                                    <div className="add-leg-flight-number">
                                        <CustomFormikField
                                            key="flightNumber"
                                            CustomComponent={TextField}
                                            name="flightNumber"
                                            label="Flight or Tail #"
                                            placeholder="AA28394"
                                            fullWidth
                                        />
                                    </div>
                                </>
                                :
                                <>
                                    <div className="add-leg-airport">
                                        <CustomFormikField
                                            CustomComponent={DateTimePicker}
                                            key="departureDate"
                                            name="departureDate"
                                            label="Departure Date"
                                            margin="normal"
                                            value={values.departureDate}
                                            onChange={date => {
                                                setFieldValue("departureDate", date, false);
                                            }}
                                            fullWidth
                                        />
                                    </div>
                                    <div className="flex add-leg-airport add-leg-airport-margin">
                                        <div className="flex align-end marginBottom10 marginRight5 pointer">
                                            <Tooltip title="Don't see your Airport? Just type in the IATA airport code and we'll do the rest!">
                                                <HelpIcon htmlColor="#000000" />
                                            </Tooltip>
                                        </div>
                                        <div className="flex1">
                                            <CustomFormikField
                                                key="origin"
                                                CustomComponent={AirportSelect}
                                                name="origin"
                                                label="Origin (airport code)"
                                                placeholder="IATA airport code"
                                                setFieldValue={setFieldValue}
                                                fullWidth
                                            />
                                        </div>
                                    </div>
                                    <div className="flex add-leg-airport add-leg-airport-margin">
                                        <div className="flex align-end marginBottom10 marginRight5 pointer">
                                            <Tooltip title="Don't see your Airport? Just type in the IATA airport code and we'll do the rest!">
                                                <HelpIcon htmlColor="#000000" />
                                            </Tooltip>
                                        </div>
                                        <div className="flex1">
                                            <CustomFormikField
                                                key="destination"
                                                CustomComponent={AirportSelect}
                                                name="destination"
                                                label="Destination (airport code)"
                                                placeholder="IATA airport code"
                                                setFieldValue={setFieldValue}
                                                fullWidth
                                            />
                                        </div>
                                    </div>
                                    <div className="add-leg-airport add-leg-airport-margin flex">
                                        <div className="flex align-end marginBottom5 marginRight5 pointer">
                                            <Tooltip title="Don't see your Aircraft? Contact us at admin@carbonfreejetsetter.com to get your aircraft added.">
                                                <a href="mailto:admin@carbonfreejetsetter.com">
                                                    <HelpIcon htmlColor="#000000" />
                                                </a>
                                            </Tooltip>
                                        </div>
                                        <div className="flex1">
                                            <CustomFormikField
                                                CustomComponent={AircraftSelect}
                                                name="aircraftId"
                                                label="Aircraft Model"
                                                placeholder="Airbus 8201"
                                                setFieldValue={setFieldValue}
                                                fullWidth
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
};

AddLegForm.defaultProps = {
    leg: {},
    legIndex: -1
};

export default AddLegForm;