import React from 'react';
import WarningIcon from "@material-ui/icons/Error";

import {formatTotalCarbon} from "../../../../utils/carbon";

import './index.css';

const TripDetailsTotals = ({trip}) => {
    let reconciled = true;
    let miles = 0;
    let estimatedCarbon = 0;
    let actualCarbon = 0;

    if (trip && trip.legs) {
        trip.legs.forEach(leg => {
            reconciled = reconciled && leg.carbonMTActual;
            miles += leg.totalMiles;
            estimatedCarbon += leg.carbonMTEstimated;
            actualCarbon += leg.carbonMTActual;
        })
    }

    return (
        <div className={`flex justify-end trip-details-totals-container ${reconciled ? "bg-primary" : "bg-secondary"}`}>
            <div className="flex center marginRight30 color-white bold">
                TOTALS
            </div>
            <div className="trip-details-total-item">
                <div className="trip-details-leg-label color-white">
                    # Miles
                </div>
                <div className="color-white">
                    {formatTotalCarbon(miles, 1)}
                </div>
            </div>
            <div className="trip-details-total-item">
                <div className="trip-details-leg-label color-white">
                    &#8494; Carbon
                </div>
                <div className="bold color-white">
                    {formatTotalCarbon(estimatedCarbon, 1)} MT
                </div>
            </div>
            <div>
                <div className="trip-details-leg-label color-white">
                    Carbon
                </div>
                <div className="bold color-white">
                    {reconciled ?
                        formatTotalCarbon(actualCarbon, 1) + " MT" :
                        <WarningIcon htmlColor="#ffffff"/>
                    }
                </div>
            </div>
        </div>
    )
};

export default TripDetailsTotals;